<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="430"
      class="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-on="on" v-bind="attrs" class="mdi-dark mdi-inactive"
          >mdi-trash-can-outline</v-icon
        >
      </template>
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="12">
              Remover Contato
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card-title>
        <v-col v-if="getLoading" cols="12" align="center">
          <v-progress-circular :size="100" color="#D70472" indeterminate></v-progress-circular>
          <h3 class="textInfoDisable mt-6">Excluindo contato...</h3>
        </v-col>
        <v-col v-show="!getLoading" class="pa-0">
          <v-card-text class="pb-md-0">
            <v-container>
              <v-row justify="center" align="center" v-if="!error && !success">
                <v-col align="center" cols="12">
                  <img src="@/assets/pink-info.png" width="100" alt="" />
                </v-col>
                <v-col class="titleRemove" align="center" cols="12">
                  <h5>
                    Confirmar remoção
                  </h5>
                </v-col>
                <v-col class="textRemove" align="center" cols="12" md="9">
                  <h5>
                    Você confirma a remoção do contato dos favoritos?
                  </h5>
                </v-col>
              </v-row>
              <v-row justify="center" align="center" v-if="error && !success">
                <v-col align="center" cols="12">
                  <img src="@/assets/erro.png" width="100" alt="" />
                </v-col>
                <v-col class="titleRemove" align="center" cols="12">
                  <h5>
                    {{ error }}
                  </h5>
                </v-col>
              </v-row>
              <v-row justify="center" align="center" v-if="!error && success">
                <v-col align="center" cols="12">
                  <img src="@/assets/successPink.png" width="100" alt="" />
                </v-col>
                <v-col class="titleRemove" align="center" cols="12">
                  <h5>
                    Contato removido
                  </h5>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-container class="pt-md-0">
              <v-row v-if="!error && !success">
                <v-col align="center" cols="12" class="pb-md-1">
                  <v-btn
                    color="grey"
                    height="40"
                    class="button"
                    outlined
                    rounded
                    @click="deleteContact"
                    >Sim</v-btn
                  >
                </v-col>
                <v-col align="center" cols="12" class="pb-md-0">
                  <v-btn color="#D70472" height="40" class="button" rounded dark @click="closeModal"
                    >Não</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="error && !success">
                <v-col align="center" cols="12">
                  <v-btn color="#D70472" height="40" class="button" rounded dark @click="closeModal"
                    >Fechar</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="!error && success" class="pb-md-1">
                <v-col align="center" cols="12">
                  <v-btn
                    color="grey"
                    height="40"
                    class="button"
                    outlined
                    rounded
                    @click="closeModal"
                  >
                    Fechar
                  </v-btn>
                </v-col>
                <v-col align="center" cols="12">
                  <v-btn color="#D70472" height="40" class="button" rounded dark @click="pushHome">
                    Voltar para Home
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: {
    id: String
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapGetters({
      success: "DigitalAccount/getSuccessDeleteContact",
      error: "DigitalAccount/getError",
      role: "Auth/getRole",
      getLoading: "Loading/getLoading"
    })
  },
  methods: {
    ...mapActions({
      deleteContactById: "DigitalAccount/deleteContact"
    }),
    ...mapMutations({
      clearError: "DigitalAccount/CLEAR_ERROR",
      clearSuccess: "DigitalAccount/CLEAR_SUCCESS_DELETE_CONTACT"
    }),
    async deleteContact() {
      await this.deleteContactById(this.id);
    },
    closeModal() {
      this.clearError();
      this.clearSuccess();
      this.$emit("reloadGrid");
      this.dialog = false;
    },
    pushHome() {
      this.clearSuccess();
      this.$router.push(`/customer/home`);
    }
  }
};
</script>
<style lang="scss" scoped>
.titleRemove > h5 {
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #d70472;
}
.textRemove > h5 {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #8c8c8c;
}
.button {
  width: 60%;
}
.button::v-deep .v-btn__content {
  text-transform: none;
  font-weight: normal;
}
@media only screen and (max-device-width: 767px) {
  ::v-deep .v-dialog.v-dialog--active.v-dialog--persistent {
    margin: 0;
  }
}
</style>
