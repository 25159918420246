<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h5 class="titleBalanceTransfer">Saldo total disponível na conta digital:</h5>
      </v-col>
      <v-col cols="12" md="3" class="text-field">
        <v-text-field
        class="inputBalanceTransfer"
          outlined
          single-line
          height="50"
          readonly
          color="#8C8C8C"
          :value="service.formatCurrency(balance)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <h5 class="titleNewTransfer">Realizar nova Transferência para:</h5>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="2" cols="6" class="pr-1 pr-md-3">
        <v-btn class="btnTransfer" @click="showFavorites" :outlined="!favorite" color="#D70472" dark>Favoritos</v-btn>
      </v-col>
      <v-col md="2" cols="6" class="pl-1" v-if="hasPermissionTransferFinago || hasPermissionTransferOthers">
        <v-btn @click="newContactTransfer()" class="btnTransfer" color="#00C1C1" outlined>
          Nova Transferência
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="favorite">
      <v-col cols="6" class="pb-md-0" v-if="$vuetify.breakpoint.mdAndUp">
        <v-icon class="iconFavorites">mdi-star</v-icon>
        <h5 class="titleTableTransfers favorites pl-2">Favoritos:</h5>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="4" class="pb-md-0">
        <v-text-field
          v-model="searchContact"
          class="searchContact"
          outlined
          label="Pesquisar contato"
          append-icon="mdi-magnify"
        />
      </v-col>
      <v-col md="12" cols="12">
        <v-slide-group
        :show-arrows="showArrows">
          <v-col @click="$router.push(`/customer/digitalAccount/newContact`)"
            md="2" cols="2" class="col-card">
            <v-card
              v-if="hasPermission"
              class="mt-1 cardContact"
              :height="$vuetify.breakpoint.mdAndUp ? '300' : '250'"
              max-width="280"
            >
              <v-row
                align="center"
                justify="center"
              >
                <v-col style="text-align:center;" class="mt-12" cols="12">
                  <h5 class="iconAddContact">+</h5>
                </v-col>
                <v-col style="text-align:center" md="12">
                  <h5 class="textCard">Novo Contato</h5>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-slide-item
            v-for="(contact, index) in getContacts"
            :key="index"
          >
            <v-card
              class="ma-4 cardContact"
              @click="newTransfer(contact)"
            >
              <v-row
              >
                <v-col class="pa-md-8 pl-8" cols="10">
                  <h5 class="titleContactCard">Apelido</h5>
                  <h5 class="dataContactCard nickName">{{contact.nickName.substring(0,20)}}</h5>
                  <h5 class="titleContactCard">Banco</h5>
                  <h5 class="dataContactCard">{{getBankName(contact.bank)}}</h5>
                  <h5 class="titleContactCard">CPF/CNPJ</h5>
                  <h5 class="dataContactCard">{{service.documentFormat(contact.document)}}</h5>
                </v-col>
                <v-col style="cursor:pointer;" @click="actionModalDelete(true)" cols="1" md="2">
                  <ModalDelete @reloadGrid="getAllContacts" :id="contact._id"/>
                </v-col>
                <v-col class="editContact" @click="editContact(contact)" cols="12">
                  <p>
                  <v-icon class="mdi-dark mdi-inactive">mdi-pencil</v-icon>
                  Editar contato
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h5 class="titleTableTransfers">Histórico de Transferências:</h5>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col v-show="!getLoading" cols="12" class="pb-12">
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="dataTableItems"
          :items-per-page="500"
          :mobile-breakpoint="600"
          class="dataTableContent ma-md-3"
        >
          <template v-slot:item.action="{ item }">
            <v-btn color="#D70472" @click="openReceipt(item.id)" width="20" dark outlined>
              Ver
            </v-btn>
          </template>
        </v-data-table>
        <seeReceipt :dialog="dialog" @closeModalReceipt="closeModal"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import seeReceipt from './modalReceipt.vue';
import ModalDelete from './ModalDelete.vue';
import service from '../../../service';
import roles from '../../../config/permissions';

export default {
  components: {
    ModalDelete,
    seeReceipt,
  },
  data() {
    return {
      service,
      favorite: false,
      showArrows: false,
      dialog: false,
      searchContact: '',
      dialogDelete: false,
      hasPermission: false,
      hasPermissionTransferFinago: false,
      hasPermissionTransferOthers: false,
      headers: [
        {
          text: 'Data',
          align: 'center',
          filterable: false,
          value: 'date',
        },
        {
          text: 'Tipo de Operação',
          align: 'center',
          filterable: false,
          value: 'type',
        },
        {
          text: 'Destinatário',
          align: 'center',
          filter: false,
          value: 'name',
        },
        {
          text: 'Valor(R$)',
          align: 'center',
          filter: false,
          value: 'value',
        },
        {
          text: 'Status',
          align: 'center',
          filter: false,
          value: 'status',
        },
        {
          text: 'Ação',
          align: 'center',
          filter: false,
          value: 'action',
        },
      ],
      textReceipt: false
    };
  },
  watch: {
    async searchContact(value) {
      if (value.length > 0) {
        await this.getContactByText(value);
      } else {
        this.getAllContacts();
      }
    },
  },
  computed: {
    ...mapGetters({
      getStatement: 'DigitalAccount/getConsultStatement',
      balance: 'DigitalAccount/getBalance',
      getContacts: 'DigitalAccount/getContacts',
      error: 'DigitalAccount/getError',
      getBanks: 'Registration/getBanks',
      role: 'Auth/getRole',
      getLoading: 'Loading/getLoading',
    }),
    dataTableItems() {
      const sortedData = this.service.dataSort(this.getStatement, 'entryDate');

      return sortedData.map(el => ({
        date: this.$moment(el.entryDate).format('DD/MM/YYYY'),
        type: (el.type === 'D' ? 'Débito' : 'Crédito'),
        name: el.name,
        value: this.service.formatCurrency(el.amount),
        status: 'Enviada',
        id: el.transactionId,
      }))
    },
  },
  methods: {
    ...mapMutations({
      setContact: 'DigitalAccount/SET_CONTACT',
      clearError: 'DigitalAccount/CLEAR_ERROR',
      clearEmailSuccess: 'DigitalAccount/CLEAR_SEND_EMAIL',
    }),
    ...mapActions({
      setBalance: 'DigitalAccount/setBalance',
      getAllContacts: 'DigitalAccount/getAllContacts',
      getContactByText: 'DigitalAccount/getAllContactsBySearchText',
      deleteContact: 'DigitalAccount/deleteContact',
      setConsultStatement: 'DigitalAccount/consultStatement',
      receiptTransfer: 'DigitalAccount/receiptTransfer',
      setBanks: 'Registration/setBanks',
      hasRole: 'User/hasRole',
    }),
    async actionModalDelete(payload) {
      this.dialogDelete = payload;
    },
    editContact(payload) {
      this.setContact(payload);
      this.$router.push(`/customer/digitalAccount/newContact`);
    },
    showFavorites() {
      this.favorite = !this.favorite;
    },
    newTransfer(payload) {
      this.setContact(payload);
      this.$router.push(`/customer/digitalAccount/newTransference`);
    },
    newContactTransfer() {
      this.$router.push(`/customer/digitalAccount/newTransference`);
    },
    async openReceipt(payload) {
      await this.receiptTransfer(payload);
      this.dialog = true;
    },
    closeModal() {
      this.$router.push({ path: `/customer/digitalAccount/transfers` });
      this.dialog = false;
      this.clearEmailSuccess();
      this.clearError();
    },
    getBankName(payload) {
      if(payload === '9999') {
        return 'Finago'
      } else {
        const bank = this.getBanks.find(item => item.key === payload);
        return bank.value;
      }
    },
  },
  async created() {
    this.setContact(null);
    await this.setBanks();
    const start = new Date (this.$moment().subtract(30, 'days').format());
    const end = new Date(this.$moment().format());
    await this.setConsultStatement({
      period:{       
          startDate: start.toISOString(),
          endDate: end.toISOString(),
      },
      direction: 'output',
      transaction: 'debit',
    });
    await this.setBalance();
    await this.getAllContacts();
    this.hasPermission = await this.hasRole(roles.CUSTOMER_WALLET_FAVORITE_CONTACT_WRITE.role);
    this.hasPermissionTransferFinago = await this.hasRole(roles.CUSTOMER_WALLET_INTERNALTRANSFER_WRITE.role);
    this.hasPermissionTransferOthers = await this.hasRole(roles.CUSTOMER_WALLET_EXTERNALTRANSFER_WRITE.role);
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/digitalAccount/transfers.scss';
</style>
